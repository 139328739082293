<template>
  <div>
    <h1>Lecturer Case Workflow</h1>
    <div id="workflow">
      <table>
        <thead>
          <tr>
            <th>&#160;</th>
            <th>Academic Departments<br>(Non-LPS)</th>
            <th>LPS Instructors<br>in Non-LPS Academic Units*</th>
            <th>LPS Instructors<br>in LPS Academic Units**</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="step-name">Case Kickoff</td>
            <td class="dept-admin">Department Faculty Affairs</td>
            <td class="lps-general">LPS General Admin</td>
            <td class="lps-program-admin">LPS Program Administrator</td>
          </tr>
          <tr>
            <td class="step-name">Dossier Compilation</td>
            <td class="dept-admin">Department Faculty Affairs</td>
            <td class="dept-admin">Department Faculty Affairs</td>
            <td class="lps-program-admin">LPS Program Administrator</td>
          </tr>
          <tr>
            <td class="step-name">Chair/Director Review</td>
            <td class="dept-chair">Department Chair/Director</td>
            <td class="dept-chair">Department Chair/Director</td>
            <td class="lps-program-director">LPS Program Director</td>
          </tr>
          <tr>
            <td class="step-name">Language Instruction Review</td>
            <td class="lang-instruction">Language Instruction Reviewer <span class="clarification">(if applicable)</span></td>
            <td class="lang-instruction">Language Instruction Reviewer <span class="clarification">(if applicable)</span></td>
            <td class="lang-instruction">Language Instruction Reviewer <span class="clarification">(if applicable)</span></td>
          </tr>
          <tr>
            <td class="step-name">School Faculty Affairs Review</td>
            <td class="fac-affairs">School Faculty Affairs</td>
            <td class="fac-affairs">School Faculty Affairs</td>
            <td class="fac-affairs">School Faculty Affairs</td>
          </tr>
          <tr>
            <td class="step-name">Associate Dean Review</td>
            <td class="assoc-dean">Associate Dean<span class="clarification">(based on Academic Unit)</span></td>
            <td class="assoc-dean">Associate Dean<span class="clarification">(based on Academic Unit)</span></td>
            <td class="assoc-dean">Associate Dean***<span class="clarification">(based on Academic Unit)</span></td>
          </tr>
          <tr>
            <td class="step-name">Budget Office Review</td>
            <td class="budget-office">Budget Office <span class="clarification">(if requested)</span></td>
            <td class="budget-office">Budget Office <span class="clarification">(if requested)</span></td>
            <td class="budget-office">Budget Office <span class="clarification">(if requested)</span></td>
          </tr>
          <tr>
            <td class="step-name">School Faculty Affairs Final Review</td>
            <td class="fac-affairs">School Faculty Affairs <span class="clarification">(if provost review required)</span></td>
            <td class="fac-affairs">School Faculty Affairs <span class="clarification">(if provost review required)</span></td>
            <td class="fac-affairs">School Faculty Affairs <span class="clarification">(if provost review required)</span></td>
          </tr>
          <tr>
            <td class="step-name">Provost Review</td>
            <td class="provost">Provost Office <span class="clarification">(if required)</span></td>
            <td class="provost">Provost Office <span class="clarification">(if required)</span></td>
            <td class="provost">Provost Office <span class="clarification">(if required)</span></td>
          </tr>
          <tr>
            <td class="step-name">School Notification</td>
            <td class="fac-affairs">School Faculty Affairs <span class="clarification">(if provost review required)</span></td>
            <td class="fac-affairs">School Faculty Affairs <span class="clarification">(if provost review required)</span></td>
            <td class="fac-affairs">School Faculty Affairs <span class="clarification">(if provost review required)</span></td>
          </tr>
          <tr>
            <td class="step-name">Counter-Signed Offer Letter Upload</td>
            <td class="dept-admin">Department Faculty Affairs</td>
            <td class="lps-general">LPS General Admin</td>
            <td class="lps-general">LPS General Admin</td>
          </tr>
          <tr>
            <td class="step-name">Enter Into Workday</td>
            <td class="rbo">Regional Business Office</td>
            <td class="rbo-lps">LPS Business Office</td>
            <td class="rbo-lps">LPS Business Office</td>
          </tr>
          <tr>
            <td class="step-name">School Finalization</td>
            <td class="fac-affairs">School Faculty Affairs</td>
            <td class="fac-affairs">School Faculty Affairs</td>
            <td class="fac-affairs">School Faculty Affairs</td>
          </tr>
        </tbody>
      </table>
      <div class="footnotes">
        <p><strong>* LPS Instructors in Non-LPS Academic units:</strong>  Traditional BA Courses (incl PBPH), Summer Session, MLA courses rostered within Departmental Academic Units</p>
        <p><strong>** LPS Instructors in LPS Academic Units:</strong> Fels, Org Dynamics, Applied Positive Psychology, Behavioral & Decision Sciences, Chemical Science, MES, MSAG, BAAS, MLA courses rostered with MLA as the Academic Unit</p>
        <p><strong>*** Associate Dean Review:</strong> For the majority of cases the Academic Unit will determine the Associate Dean for approval.  In cases where the Academic Unit is not associated with a particular division (e.g. MLA, BAAS) the division will be chosen by the initiator of the case in the Case Kickoff Application.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Workflow',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #workflow {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  table {
    border-collapse: collapse;
    font-size: 20px;
    width: 80%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  td, th {
    border-right: 5px solid black;
    border-bottom: 1px solid black;
    height: 50px;
  }
  th {
    border-bottom: 5px solid black;
  }
  span.clarification {
    font-style: italic;
    display: block;
    font-size: 16px;
  }
  td.step-name {
    font-weight: bold;
  }
  div.footnotes {
    width: 80%;
    text-align: left;
    flex-grow: 0;
    flex-shrink: 0;
  }

  td.fac-affairs { background-color: #cddfb2 }
  td.dept-admin { background-color: #fbf2cb }
  td.dept-chair { background-color: #f7e89a }
  td.lang-instruction { background-color: #ddbcf3 }
  td.lps-general { background-color: #dce0f4 }
  td.lps-program-admin { background-color: #bbc5e9 }
  td.lps-program-director{ background-color: #96a8dc }
  td.budget-office {background-color: #d0d0ce }
  td.assoc-dean {background-color: #b4d08e }
  td.provost {background-color: #ffffff }
  td.rbo {background-color: #aeaaaa }
  td.rbo-lps {background-color: #b0b7ca }

</style>
